//----------------------------
//-----Functions
//----------------------------
@import 'config/functions/_colorFunction.scss',
'config/functions/_fontFunction.scss',
'config/functions/_zIndexFunction.scss';
//----------------------------
//-----Config
//----------------------------
@import 'config/_reset.scss',
'config/_baseConfig.scss',
'config/variables/_colors.scss',
'config/variables/_fonts.scss',
'config/variables/_breakpoints.scss',
'config/variables/_zIndex.scss',
'config/_typography';
//----------------------------
//-----Mixins
//----------------------------
@import 'config/mixins/_breakpoints.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Devonshire&subset=latin-ext');
//----------------------------
//-----Library
//----------------------------
@import 'lib/bootstrap.scss';
//----------------------------
//-----CMS Styles
//----------------------------
.ql-align-justify {
    text-align: justify;
}

//----------------------------
//-----Global overrides
//----------------------------
body {
    font-family: $font-main;
}

ul,
ol {
    margin-left: 20px;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

a {
    color: $main-color;
    transition: all .2s ease;
    &:hover,
    &:focus {
        color: #b9b9b9;
        transition: all .2s ease;
    }
}

//----------------------------
//-----Custom styles
//----------------------------
.navbar-default {
    background: #fff;
    .logo-without-image {
        line-height: 100px;
        padding: 5px 20px;
        font-family: 'Devonshire', cursive;
        font-size: 40px;
        text-transform: capitalize;
    }
}

.text-muted {
    padding: 20px 0;
}

.section {
    padding: 100px 0;
    overflow: hidden;
    p,
    a {
        font-weight: 300;
    }
    .section-heading {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        font-size: 22px;
        margin: 0 0 40px 0;
        &:after {
            content: " ";
            width: 100px;
            bottom: 50%;
            margin-left: 10px;
            position: absolute;
            border-bottom: 1px solid #e5e3e3;
        }
    }
}

.navbar {
    margin: 0;
}

.navbar-header {
    min-height: 110px;
}

.navbar-toggle {
    margin-top: 35px;
    margin-bottom: 35px;
}

.navbar-nav {
    padding: 33px 0;
    @include breakpoint (toDesktop) {
        padding: 0;
    }
    li {
        margin-left: 30px;
        @include breakpoint (toTablet) {
            margin-left: 10px;
        }
    }
    &>li>a {
        padding: 10px 0 10px 0;
    }
}

.lang-buttons {
    display: block;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    font-size: 14px;
    height: 42px;
}

.navbar-brand {
    padding: 25px 15px;

    &.logo img {
        max-width: 200px;
        margin-top: 5px;
        margin-bottom: 10px;
        max-height: 50px;
    }
}

.navbar-default .navbar-nav>li>a {
    color: #3b3b3b;
    border-bottom: 2px solid transparent;
    &:hover {
        color: $main-color;
        border-bottom: 2px solid $main-color;
    }
}

.vertical-center {
    min-height: 60%;
    min-height: 60vh;
    display: flex;
    align-items: center;
}

.coming-soon {
    .jumbotron {
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 100vw;
        height: 100vh;
        min-height: 100%;
        display: table-cell;
        overflow: hidden;
        padding: 45px 15px;
    }
    .logo-image {
        position: absolute;
        margin: 0 auto;
        top: 20px;
        left: 0;
        right: 0;
    }
    .social-buttons {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        p {
            color: #fff;
            padding: 15px;
        }
        .fa {
            background: #fff;
            padding: 15px;
            border-radius: 50%;
            color: #3b3b3b;
            font-size: 25px;
            transition: all 0.3s ease;
            &:hover {
                color: #fff;
                background: $main-color;
                transition: all 0.3s ease;
            }
        }
    }
}

.icon-title {
    background: $main-color;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
}

.jumbotron {
    margin-bottom: 0;
    padding: 15px;

    @media all and (max-width: 480px) {
        padding: 5px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: #fff;
        font-family: $font-secondary;
    }
    h1 {
        font-size: 100px;
        margin: 0;

        @media all and (max-width: 992px) {
            font-size: 40px;
        }
    }
    p {
        color: #fff;
        font-family: "Roboto Slab", serif;
        position: relative;
        font-weight: 700;
        font-size: 16px;
        font-style: italic;
        letter-spacing: 0.5px;
        display: inline-block;
        margin: 20px;
    }
    .separator {
        display: inline-block;
        margin: 0 20px;
        width: 50px;
        vertical-align: middle;
        border-top: 1px solid;
    }
    .container {
        width: 100%;
    }
    .learn-more {
        padding: 10px 30px;
        background-color: #f04a49;
        border-radius: 3px;
        border: none;
        color: #fff;
        font-family: "Roboto Slab", serif;
        transition: all 0.4s ease 0s;
        cursor: pointer;
    }
    .learn-more:hover {
        background-color: rgb(255, 90, 90);
        text-decoration: none;
    }
}

.menu {
    border-top: 4px solid $main-color;
    .menu-right {
        padding-left: 50px;
    }
    .menu-left {
        padding-right: 50px;
    }
    .menu-container {
        overflow: hidden;
    }
    .menu-title {
        display: inline-block;
        font-weight: 700;
        margin: -1px 0 30px 0;
        padding: 20px 25px 35px 25px;
        background: url("../img/ribbon-section.svg") no-repeat;
        background-position: 50% 0;
        color: #fff;
    }
    .menu-item-title {
        color: #545454;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        &:after {
            content: " ";
            width: 100%;
            bottom: 40%;
            z-index: -1;
            margin-left: 10px;
            position: absolute;
            border-bottom: 1px solid #e5e3e3;
        }
    }
    .menu-item-title {
        float: left;
        &.pp-small {
            width: 70%;
        }
        &.pp-large {
            width: 80%;
        }
    }
    .menu-item-price {
        float: right;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: right;
        color: #545454;
        font-size: 16px;
        line-height: 1em;
        background: #fff;
        z-index: 1;
        &.pp-small {
            width: 20%;
        }
        &.pp-large {
            width: 30%;
        }
    }
    .menu-item-description {
        color: #b7b7b7;
        font-size: 12px;
        font-style: italic;
    }
    .hidden-menu {
        height: 190px;
    }
}

.news {
    .news-right {
        padding-left: 50px;
    }
    .news-left {
        padding-right: 50px;
    }
    .news-single-title {
        margin: 20px 0;
        position: relative;
        &:after {
            content: " ";
            width: 100px;
            bottom: 50%;
            margin-left: 10px;
            position: absolute;
            border-bottom: 1px solid #e5e3e3;
        }
        .fa {
            color: #3b3b3b;
            border-radius: 5px;
            margin-right: 10px;
        }
    }
    .news-single-info {
        margin: 20px 0;
    }
    h1,
    h2,
    h3 {
        margin: 20px 0;
    }
    p {
        margin: 0;
    }
    a {
        color: darken( $main-color, 20%) !important;
        text-decoration: underline;
        transition: all .2s ease;
        &:hover {
            color: #000 !important;
            text-decoration: underline;
            transition: all .2s ease;
        }
    }

    .content p {
        word-break: break-all;
    }
    .hidden-news {
        height: 400px;
        overflow: hidden;
        &.active {
            opacity: 1;
            height: none;
            transition: all 0.5s ease;
        }
    }
}

.show-more-btn {
    display: inline-block;
    color: #b7b7b7;
    margin-top: 30px;
    padding: 10px 15px;
    border: 1px solid #d7d5d5;
    color: #b7b7b7;
    .fa {
        border-left: 1px solid;
        padding-left: 3px;
    }
}

.products {
    padding: 100px 0;
    overflow: hidden;
    background: #f7f7f7;
    .section-heading {
        display: inline;
        width: 80%;
        float: left;
        margin: 0 0 40px 0;
    }
    .product-slider {
        display: inline;
        text-align: right;
        width: 20%;
        float: right;
    }
    .section-heading-container {
        padding-bottom: 50px;
    }
    .img-holder {
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;
        background: #fff;
        height: 250px;
    }
    .product-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 55%
    }
    .product-info {
        display: flex;
        margin: 20px 15px;
        padding: 5px;
        background: #fff;
        min-height: 78px;
    }
    
    .product-informations {
        height: 335px;
    }
    
    
    .product-title,
    .product-price {
        font-size: 16px;
        font-weight: 500;
    }
    .product-title {
        word-wrap: break-word;
        vertical-align: text-top;
        margin: 0;
        position: relative;
        padding-right: 5px;
        &.pp-small {
            flex: 7;
        }
        &.pp-large {
            flex: 8;
        }
    }
    .product-price {
        word-wrap: break-word;
        vertical-align: text-top;
        text-align: center;
        margin: 0;
        position: relative;
        bottom: 2px;
        &.pp-small {
            flex: 2;
        }
        &.pp-large {
            flex: 3;
        }
    }
    .product-single {
        display: none;
        margin: 0;
        padding: 5px 10px 0px 10px;
        position: relative;
        
    }
    .ajax-loading-container {
        display: none;
        position: absolute;
        text-align: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: rgba($main-color, 0.7);
        margin: 0 auto;
        border-radius: 10px;
        .ajax-loading {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
    }
    .zoom__product {
        background-color: $main-color;
        color: #fff;
        width: 100%;
        display: none;
        margin-bottom: 18px;

        @media all and (max-width: 992px) {
            display: block;
        }

        &:hover {
            background-color: darken($main-color, 10%);
        }
    }
   
    
}

.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.vertical-align-center {
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}

.modal-content {
    width: inherit;
    height: inherit;
    margin: 0 auto;
    pointer-events: all;
}

.modal-footer .text-left {
    word-break: break-all;
}

.modal {
    margin: 50px;
    overflow: auto;
}

.modal-text p{
    word-break: break-all;
    padding: 10px;
}

.modal-text ul {
    word-break: break-all;
    padding-left: 10px;
    padding-right: 5px;
}

.modal-text ol {
    word-break: break-all;
    padding-left: 10px;
    padding-right: 5px;
}

.list-unstyled {
  text-align: left;
}
#product-modal {
    #lightgallery {
        overflow: hidden;
    }

}
.products .no-images .product-info {
    display: block;
}
.product-title-container,
.product-price-container,
.product-description-container {
    width: 100%;
    display: block;
    text-align: justify;
    padding: 5px 20px;
}
.gallery {
    .masonry-gallery {
        font-size: 0;
        margin: 0;
    }
    .item-gallery {
        position: relative;
        width: 33.33%;
        display: inline-block;
        font-size: 0;
    }
    .info {
        font-size: 15px;
    }
    .modal-title {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px;
    }
    p {
        font-weight: 400;
        font-style: italic;
        margin: 5px;
    }
    .modal-content {
        background: $main-color;
        color: #fff;
        border: none;
    }
    .modal-header {
        border-bottom: 0;
    }
    .modal-body {
        padding: 0;
    }
    .modal-footer {
        border-top: 0;
        margin: 0;
    }
    .toggle-left-container,
    .toggle-right-container {
        .fa {
            color: #fff;
            &:hover {
                color: #efefef;
            }
        }
    }
    .modal-header .close {
        opacity: 0.8;
        color: #fff;
        &:hover,
        &:focus {
            color: #efefef;
        }
    }
}

.mypage-modal {
    .close {
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 1;
    }
    .modal-header {
        height: 0;
        margin: 0;
        padding: 0;
        min-height: 0;
    }
    .modal-title {
        display: none;
    }
    .modal-footer {
        display: none;
    }
}

.mypage1 {
    .section-heading {
        margin-top: 40px;
    }
}

.mypage2,
.mypage1 {
    .hovereffect .info {
        background: transparent !important;
        position: relative;
        margin: 0;
        top: 50%;
        transform: translateY(-50%) !important;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contact-banner {
    background: #3b3b3b;
    color: #fff;
    padding: 15px 0;
    font-family: $font-secondary;
    h4 {
        font-size: 30px;
        padding: 20px 0;
    }
    h4 {
        margin: 0;
    }
    .list-inline {
        margin: 0 0 15px 0;
    }
    .fa {
        color: #fff;
        transition: all .2s ease;
    }
    .fa:hover {
        color: $main-color;
        transition: all .2s ease;
    }
}

.section-heading {
    position: relative;
}

.contact {
    background: $main-color;
    color: #fff;
    padding: 100px 0;
    .section-heading {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        font-size: 22px;
        &:after {
            content: " ";
            width: 100px;
            bottom: 50%;
            margin-left: 10px;
            position: absolute;
            border-bottom: 1px solid lighten($main-color, 20%);
        }
    }
    ul,
    ol {
        list-style: none;
        margin: 0;
    }
    a {
        color: #fff;
        &:hover {
            color: #3b3b3b;
        }
    }
    .btn {
        width: 30%;
        padding: 15px;
        border-radius: 0;
        font-size: 15px;
        background-color: #3b3b3b;
        border-color: #060606;
        &:hover {
            background-color: #060606;
            border-color: #000000;
        }
    }
    .info-container {
        display: inline-block;
        width: 100%;
        margin: 15px 0;
        max-height: 200px;
        img {
            max-height: 200px;
        }
    }
    .form-control {
        border-radius: 0;
        padding: 15px;
        height: 50px;
        font-size: 15px;
        max-width: 100%;
        &:focus {
            border: 1px solid #3b3b3b;
        }
    }
    textarea.form-control {
        height: auto;
    }
    #google-map {
        height: 400px;
        width: 100%;
        margin: 0 0 30px 0;
    }
}

footer {
    width: 100%;
    padding: 100px 0;
    background: #3b3b3b;
    overflow: hidden;
    a,
    p {
        color: #fff;
    }
    a {
        border-bottom: 2px solid transparent;
        &:hover {
            border-bottom: 2px solid transparent;
        }
    }
    .logo {
        margin: 0 0 20px 20px;
        width: 100%;
        max-height: 200px;
        img {
            max-height: 200px;
        }
    }
    .logo-text {
        float: none;
    }
    .list-unstyled li {
        color: #fff;
        font-size: 17px;
        line-height: 1.8em;
        font-weight: 300;
        letter-spacing: 0.5px;
        &:last-child,
        &:first-child {
            font-weight: 700;
        }
    }
}


// pager
  .pager-list {
    .pager {
      padding-left: 10px;
    }
    .page-prew {
      text-align: center;
    }
  }

  //filter buttons
  .category-button {
      border: none;
      background-color: #F7F7F7;
      font-size: 17px;

      &.active {
        color: #F04A49;
        box-shadow:none;
        border-color: 0;
        background-color: #F7F7F7;

      }

      &:hover {
        background-color: #F7F7F7;
        color: #F04A49;
      }

      &:focus, &:active {
        box-shadow: none;
        background-color: #F7F7F7;
      }
  }





//----------------------------
//-----Vendor
//----------------------------
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    border-radius: 35px;
    display: none;
    transition: all 0.3s linear;
    &:hover {
        background: rgba(0, 0, 0, 0.9);
        i {
            top: 5px;
            color: #fff;
        }
    }
    .fa {
        color: #fff;
        margin: 0;
        position: relative;
        left: 16px;
        top: 13px;
        font-size: 19px;
        transition: all 0.3s ease;
    }
}

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
}

.hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}

.hovereffect img {
    display: block;
    position: relative;
    transition: all 0.4s ease-in;
}

.hovereffect:hover img {
    filter: grayscale(1) blur(3px);
    transform: scale(1.2);
}

.hovereffect h2 {
    text-transform: uppercase;
    text-align: center;
    position: relative;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    font-size: 17px;
    font-weight: 700;
    font-style: italic;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
}

.hovereffect a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    font-weight: 400;
    border: 1px solid #efefef;
    margin: 50px 0 0 0;
    background-color: transparent;
}

.hovereffect a.info:hover {
    box-shadow: 0 0 5px #efefef;
}

.hovereffect a.info,
.hovereffect h2 {
    transform: scale(0.7);
    background: transparent;
    transition: all 0.4s ease-in;
    opacity: 0;
    filter: alpha(opacity=0);
    color: #fff;
    text-transform: uppercase;
}

.hovereffect:hover a.info,
.hovereffect:hover h2 {
    opacity: 1;
    background: $main-color;
    filter: alpha(opacity=100);
    transform: scale(1);
}

/* Keyframes */


/*empty*/

@keyframes empty {
    0% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes backSlideOut {
    25% {
        opacity: .5;
        transform: translateZ(-500px);
    }
    75% {
        opacity: .5;
        transform: translateZ(-500px) translateX(-200%);
    }
    100% {
        opacity: .5;
        transform: translateZ(-500px) translateX(-200%);
    }
}

@keyframes backSlideIn {
    0%,
    25% {
        opacity: .5;
        transform: translateZ(-500px) translateX(200%);
    }
    75% {
        opacity: .5;
        transform: translateZ(-500px);
    }
    100% {
        opacity: 1;
        transform: translateZ(0) translateX(0);
    }
}

@keyframes scaleToFade {
    to {
        opacity: 0;
        transform: scale(.8);
    }
}

@keyframes goDown {
    from {
        transform: translateY(-100%);
    }
}

@keyframes scaleUpFrom {
    from {
        opacity: 0;
        transform: scale(1.5);
    }
}

@keyframes scaleUpTo {
    to {
        opacity: 0;
        transform: scale(1.5);
    }
}

//----------------------------
//----- No JavaScript enabled
//----------------------------
.no-js {
    section.product-single {
        display: inline-block;
        width: calc(30.33% - 20px);
        margin: 20px;
        padding: 20px;
    }
    .navbar-collapse {
        display: block;
    }
    .show-more-btn,
    .hovereffect:hover .overlay {
        display: none;
    }
    .hovereffect:hover img {
        filter: none;
        transform: none;
    }
    .menu .hidden-news,
    .news .hidden-news {
        height: auto;
    }
}

.news-item-modal {
    z-index: 10000;
    position: relative;
}

#productModal::-webkit-scrollbar {
    display: none;
}

.product-single {
    cursor: pointer;
}

#productModal {
    .modal-footer {
        margin-top: 0;
    }

    .list-unstyled {
        margin: 0;
    }

    .control-buttons {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;

        button {
          background-color: #F04A49;
          border-color: #F04A49;
          margin-right: 10px;
        }
    }

    .carousel {

        #productNext {
            right: 10px;
        }

        #productPrevious {
            left: 10px;
        }

        #productNext, #productPrevious {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            background: none;
            color: #393939;
        }

        .carousel-control.right, .carousel-control.left {
            background: none;
        }

        .item {
            height: 200px;

            > img {
                margin: 0 auto;
                left: 0;
                right: 0;
                top: 50%;
                max-height: 100%;
                position: relative;
                transform: translateY(-50%);
                padding: 0 20px;
            }
        }

    }
}

.modal-image{
    margin: 0 auto;
}

.uputstva {
    display: none;
    margin-bottom: 20px;
}

.header {
    background-size: cover;
    background-position: center;
}

//----------------------------
//----- Media Queries
//----------------------------
@media (max-width: 1200px) {
    .navbar .container {
        width: 100%;
    }
    .navbar-header {
        float: none;
    }
    .navbar-left,
    .navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in {
        display: block !important;
    }
    .navbar-nav li {
        margin-left: 30px;
    }
    .navbar-default .navbar-nav>li>a:hover {
        color: #333;
        border-bottom: 2px solid transparent;
    }
}

// Desktop
@media screen and (max-width: 991px) {
    .my-image-container {
        margin: 30px 0;
        display: inline-block;
    }
    .gallery .item-gallery {
        width: 50%;
    }
    .menu .menu-left {
        padding-right: 15px;
    }
    .menu .menu-right {
        padding-left: 15px;
    }
    .navbar-nav {
        padding: 0;
    }
    .products .product-slider {
        width: 100%;
        text-align: left;
    }
    .footer-widgets {
        padding: 0 20px 20px 20px;
    }
    
    .info-container img {
        margin: 0 auto;
    }
    
    .info-container {
        text-align: center;
    }
}

// Tablet
@media screen and (max-width: 767px) {
    .container {
        margin: 0 20px;
        padding-left: 0;
        padding-right: 0;
    }
    .navbar .container {
        width: auto;
    }
    .products .section-heading,
    .section .section-heading {
        width: 100%;
    }
    .gallery .item-gallery {
        width: 100%;
    }
    .jumbotron .separator {
        display: none;
    }

    .item-gallery {
      margin-bottom: 15px;
    }

    .products {
        padding: 100px 15px;
    }
    
    .logo img {
        margin: 0 auto;
    }
    .footer-widgets .list-unstyled {
	    text-align: center;
    }
}



@media screen and (max-width: 480px) {
    .menu .menu-item-title.pp-large {
        width: 100%;
    }
    .menu .menu-item-price.pp-small {
        width: 100%;
        text-align: left;
        margin: 0 0 20px 0;
    }

  .jumbotron {
    h1 {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 320px) {
  .jumbotron {
    h1 {
      font-size: 40px;
    }
  }

  #rc-imageselect, .g-recaptcha {
    transform: scale(0.92);
    -webkit-transform: scale(0.92);
    transform-origin: 0 0;
    -webkit-transform-origin:0 0;
  }
}

@media screen and (max-width: 667px) and (orientation: landscape) {
  .navbar-nav > li > a {
    line-height: 6px;
  }
}
