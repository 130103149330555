//-------------------------
//---------------TYPOGRAPHY
//-------------------------

//------FONT SIZES
html {
	font-size: 62.5%;
}

body {
	font-size: 100%;
	font-family: fontFunc(family), fontFunc(fallback);
}

//------DEFAULT STYLES
b,
strong {
	font-weight: bold;
}

i,
em {
	font-style: italic;
}

u {
	text-decoration: underline;
}

//------STYLES
h1 {
  font-size: 7.2rem;
}

h2 {

}

h3 {

}

h4 {

}

h5 {

}

h6 {

}

p {

}

span {

}
